
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { AUTH_LOGOUT } from '@/store/actions/auth'
import LoginModal from '@/components/Login.vue'

export default Vue.extend({
    name: 'navigation',
    components: {
        LoginModal,
    },
    data() {
        return {
            login_modal_visable: false
        }
    },
    methods: {
        logout: function () {
            this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/'))
        },
        home: function () {
            return this.$route.path === '/'
        }
    },
    computed: {
        ...mapGetters(['isAuthenticated', 'isAdmin']),
        ...mapState({
            authLoading: (state: any) => state.auth.status === 'loading',
            name: (state: any) => `${state.user.profile.first_name}`,
        }),
    }
})

