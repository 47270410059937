
import Vue from 'vue'
import Navigation from '@/components/Navigation.vue'
import { AUTH_REQUEST } from '@/store/actions/auth'
import { VERSION_REQUEST } from '@/store/actions/setting.ts'
import { SETTINGS_REQUEST } from '@/store/actions/setting.ts'
import { CONTENT_REQUEST } from '@/store/actions/website_content.ts'
import { VIEWS_ADD } from '@/store/actions/views.ts'

export default Vue.extend({
    name: 'app',
    data: function() {
        return {
            version: null as string | null,
        }
    },
    components: {
        Navigation,
    },
    methods: {
        checkVersion() {
            return new Promise<void>((resolve, reject) => {
                this.version = localStorage.getItem('version')
                this.$store.dispatch(VERSION_REQUEST)
                    .then(async (version) => {
                        if (!this.version) {
                            localStorage.setItem('version', version)
                            this.version = version
                        } else if (this.version !== version) {
                            const cacheList = await window.caches.keys()
                            for (let i=0; i<cacheList.length; i++){
                                await window.caches.delete(cacheList[i])
                            }
                            // your cache is now deleted
                            localStorage.setItem('version', version)
                            location.reload()
                        }
                        resolve()
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
    },
    created: function() {
        this.checkVersion()
        this.$store.dispatch(VIEWS_ADD)

        if (this.$store.getters.isAuthenticated) {
            this.$store.dispatch(AUTH_REQUEST).then(() => {
                /*
                if (!this.$store.state.user.profile.accept_privacy_policy) {
                    this.$bvModal.show('PolicyPopup-modal')
                }
                */
            })
        }
    },
    mounted() {
        this.$store.dispatch(CONTENT_REQUEST)
        this.$store.dispatch(SETTINGS_REQUEST)

        // Add 'any' type to this by defining that ;) (otherwise typescript doesn't understands the type of this in bind)
        let that: any = this
        // Reload the settings and check for version update every 60 seconds
        setInterval(async function () {
            await that.checkVersion()
            await that.$store.dispatch(SETTINGS_REQUEST)
        }.bind(that), 60000)
    }
})
