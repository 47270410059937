import { VIEWS_SUCCESS, VIEWS_ERROR, VIEWS_REQUEST, VIEWS_ADD, VIEWS_MARK, VIEWS_GET } from '@/store/actions/views'

import apiCall from '@/utils/api'
import Vue from 'vue'

const state = {
    status: '',

    views: {},
}

const getters = {
    getViews: (state: any) => state.views,
}

const actions = {
    [VIEWS_GET]: ({ commit }: any) => {
        commit(VIEWS_REQUEST)
        apiCall({ url: '/views', method: 'GET'})
            .then(resp => {
                commit(VIEWS_GET, resp)
            })
            .catch(err => {
                commit(VIEWS_ERROR)
            })
    },
    [VIEWS_ADD]: async ({ commit, dispatch }: any, content: any) => {
        commit(VIEWS_REQUEST)
        await apiCall({ url: '/views/add', method: 'GET'})
            .then(resp => {
                commit(VIEWS_SUCCESS)
            })
            .catch(() => {
                commit(VIEWS_ERROR)
            })
    },
    [VIEWS_MARK]: async ({ commit, dispatch }: any, data: any) => {
        commit(VIEWS_REQUEST)
        await apiCall({ url: '/website_content/set', data, method: 'POST'})
            .then(resp => {
                commit(VIEWS_MARK, data)
                commit(VIEWS_SUCCESS)
            })
            .catch(() => {
                commit(VIEWS_ERROR)
            })
    }
}

export interface ViewsItem {
    ip: string,
    timestamps: any,
    mark: string,
}

const mutations = {
    [VIEWS_REQUEST]: (state: any) => {
        state.status = 'loading'
    },
    [VIEWS_SUCCESS]: (state: any) => {
        state.status = 'success'
    },
    [VIEWS_ERROR]: (state: any) => {
        state.status = 'error'
    },
    [VIEWS_GET]: (state: any, resp: any) => {
        const content_object = resp.reduce((obj: Object, item: ViewsItem) => {
            return {
                ...obj,
                [item.ip]: { 'timestamps': item.timestamps, 'mark': item.mark },
            }
        }, {})
        Vue.set(state, 'views', content_object)
    },
    [VIEWS_MARK]: (state: any, data: any) => {
        Vue.set(state.views, data.ip, data.mark)
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
