import axios from 'axios'
import Vue from 'vue'

import router from '@/router/index'

import {
    AUTH_LOGIN,
    FORGOT_PASSWORD_REQUEST,
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from '@/store/actions/auth'
import apiCall from '@/utils/api'

const state = {
    token: localStorage.getItem('user-token') || '',
    status: '',
    hasLoadedOnce: false,

    user: {},  // Own profile
}

const getters = {
    isAuthenticated: (state: any) => !!state.token,
    authStatus: (state: any) => state.status,

    isAdmin: (state: any) => state.user.role === 'admin',
}

const actions = {
    [AUTH_LOGIN]: ({ commit }: any, user: {}) => {
        commit(AUTH_REQUEST)
        return new Promise((resolve, reject) => {
            apiCall({ url: '/users/login', data: user, method: 'POST' })
                .then(async (resp: any) => {
                    localStorage.setItem('user-token', resp.token)

                    // Here set the header of your ajax library to the token value.
                    // example with axios
                    axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp.token

                    commit(AUTH_LOGIN, resp)
                    commit(AUTH_SUCCESS, resp.user)

                    resolve(resp)
                })
                .catch(err => {
                    //alert('Onjuiste credentials')
                    commit(AUTH_ERROR, err)
                    localStorage.removeItem('user-token')
                    reject(err)
                })
        })
    },
    [AUTH_REQUEST]: ({ commit }: any) => {
        return new Promise<void>((resolve) => {
            commit(AUTH_REQUEST)

            // Here set the header of your ajax library to the token value.
            // example with axios
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + state.token
            apiCall({ url: '/users', method: 'GET' })
                .then(async (resp: any) => {
                    commit(AUTH_SUCCESS, resp)
                    resolve(resp)
                })
                .catch(() => {
                    alert('Uw login-sessie is verlopen, log opnieuw in.')
                    localStorage.removeItem('user-token')
                    commit(AUTH_LOGOUT)
                    router.push('/')
                    resolve()
                })
        })
    },
    [FORGOT_PASSWORD_REQUEST]: ({ commit }: any, login_id: string) => {
        commit(AUTH_REQUEST)
        return new Promise<void>((resolve, reject) => {
            apiCall({ url: '/users/forgot_password', data: { email: login_id }, method: 'POST'})
                .then(() => {
                    commit(AUTH_SUCCESS)
                    resolve()
                })
                .catch((err) => {
                    commit(AUTH_ERROR)
                    reject(err)
                })
        })
    },
    [AUTH_LOGOUT]: ({ commit }: any) => {
        return new Promise<void>((resolve, reject) => {
            localStorage.removeItem('user-token')
            commit(AUTH_LOGOUT)
            apiCall({ url: 'users/logout', method: 'POST' })
                .then(() => { })
                .catch(err => {
                    alert('Unable to remove session from server.. try again?')
                    reject(err)
                })
            resolve()
        })
    }
}

const mutations = {
    [AUTH_REQUEST]: (state: any) => {
        state.status = 'loading'
    },
    [AUTH_LOGIN]: (state: any, resp: any) => {
        state.status = 'set token'
        state.token = resp.token
    },
    [AUTH_SUCCESS]: (state: any, resp: any) => {
        state.status = 'success'
        Vue.set(state, 'user', resp)
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state: any) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state: any) => {
        state.token = ''
        state.user = {}
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
