import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

// Enable api-calls via axios
import axios from 'axios'

// Add bootstrap styling
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

// Add custom stylings
import '@/assets/headings.css'

// Add CKEditor 5 - A rich text editor
import CKEditor from '@ckeditor/ckeditor5-vue'
Vue.use( CKEditor )

const token = localStorage.getItem('user-token')
if (token) {
    axios.defaults.headers.common['Authorization'] = token
}

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
