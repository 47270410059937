//import axios from 'axios'
const axios = require('axios')

axios.defaults.baseURL = window.location.protocol + '//' + window.location.hostname + process.env.VUE_APP_ENV_API_ENDPOINT

interface Response {
    data: any
}

const apiCall = ({ url, method, ...args }: any) =>
    new Promise((resolve, reject) => {
        setTimeout(() => {
            try {
                if (method == 'POST') {
                    axios.post(url, args.data)
                        .then((response: Response) => {
                            resolve(response.data)
                        })
                        .catch((error: any) => {
                            reject(error)
                        })
                } else {  // Assume the default is a GET request
                    return axios.get(url, {params: args.params})
                        .then((response: Response) => {
                            resolve(response.data)
                        })
                        .catch((error: any) => {
                            reject(error)
                        })
                }
            } catch (err) {
                reject(new Error(err))
            }
        }, 1000)
    })

export default apiCall
