import { SET_CONTENT_REQUEST, CONTENT_REQUEST, CONTENT_ERROR, CONTENT_SUCCESS } from '@/store/actions/website_content'
import apiCall from '@/utils/api'
import Vue from 'vue'

const state = {
    status: '',

    content: {}
}

const getters = {
    getContent: (state: any) => state.content,
}

const actions = {
    [CONTENT_REQUEST]: ({ commit }: any, name: string) => {
        commit(CONTENT_REQUEST)
        apiCall({ url: '/website_content', params: {name: name}, method: 'GET'})
            .then(resp => {
                commit(CONTENT_SUCCESS, resp)
            })
            .catch(err => {
                if (err.response.status == 400) {
                    commit('NEW_CONTENT', name)
                } else {
                    commit(CONTENT_ERROR)
                }
            })
    },
    [SET_CONTENT_REQUEST]: async ({ commit, dispatch }: any, content: any) => {
        commit(CONTENT_REQUEST)
        await apiCall({ url: '/website_content/set', data: content, method: 'POST'})
            .then(resp => {
                commit(CONTENT_SUCCESS, resp)
            })
            .catch(() => {
                commit(CONTENT_ERROR)
            })

        dispatch(CONTENT_REQUEST, content.name)
    }
}

interface ContentItem {
    name: string,
    content: any
}

const mutations = {
    [CONTENT_REQUEST]: (state: any) => {
        state.status = 'loading'
    },
    [CONTENT_SUCCESS]: (state: any, resp: any) => {
        state.status = 'success'
        if (Array.isArray(resp)) {
            const content_object = resp.reduce((obj: Object, item: ContentItem) => {
                return {
                    ...obj,
                    [item.name]: item.content,
                }
            }, {})
            Vue.set(state, 'content', content_object)
        } else {
            Vue.set(state.content, resp.name, resp.content)
        }
    },
    'NEW_CONTENT': (state: any, name: string) => {
        state.status = 'success'
        Vue.set(state.content, name, '')
    },
    [CONTENT_ERROR]: (state: any) => {
        state.status = 'error'
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
