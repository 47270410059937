import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import auth from '@/store/modules/auth'
import website_content from '@/store/modules/website_content'
import setting from '@/store/modules/setting'
import views from '@/store/modules/views'

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        website_content,
        setting,
        views
    }
})
