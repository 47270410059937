import Vue from 'vue'
import VueRouter, { RouteConfig, Route, NavigationGuard } from 'vue-router'
import store from '@/store' // your vuex store

Vue.use(VueRouter)

type Next = Parameters<NavigationGuard>[2];

const ifNotAuthenticated = (to: Route, from: Route, next: Next) => {
    if (!store.getters.isAuthenticated) {
        next()
        return
    }
    next('/account')
}

const ifAuthenticated = (to: Route, from: Route, next: Next) => {
    if (store.getters.isAuthenticated) {
        next()
        return
    }
    next('/')
}

const ifAdminAuthenticated = (to: Route, from: Route, next: Next) => {
    if (store.getters.isAdmin) {
        next()
        return
    }
    next('/')
}

// Definitions of the paths
const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: () => import(
            /* webpackPrefetch: true */
            '@/views/Home.vue'
        ),
        // beforeEnter: ifNotAuthenticated,
    },
    /*
    {
        path: '/resume',
        name: 'Resume',
        component: () => import(
            '@/views/Resume.vue'
        ),
    },
    */
    {
        path: '/cv',
        name: 'CV',
        component: () => import(
            '@/views/CV.vue'
        ),
    },
    /*
    {
        path: '/contact',
        name: 'Contact',
        component: () => import(
            '@/views/Contact.vue'
        ),
    },
    */
    {
        path: '/account',
        name: 'Account',
        component: () => import(
            '@/views/Account.vue'
        ),
        beforeEnter: ifAuthenticated,
    },
    {
        path: '/statistics',
        name: 'Statistics',
        component: () => import(
            '@/views/Statistics.vue'
        ),
        beforeEnter: ifAdminAuthenticated,
    },
]


const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
